import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SettingsService } from '@api/settings.service';
import { catchError, Observable, of } from 'rxjs';

export enum StatisticsContextEnum {
  MAIN_BOARD = 'main_board',
  MAIN_BOARD_NAVIGATE = 'main_board_section_navigate',
  MAIN_BOARD_OPTIONS = 'main_board_options',
  MAIN_BOARD_BRIGADE = 'main_board_change_brigade_level',
  MAIN_BOARD_DOWNLOAD = 'main_board_download',
  MAIN_BOARD_CONTEXT_MENU = 'main_board_profile_context_menu',
  FLAT_PARAMS = 'flat_parameters_screen',
  WIZARD = 'vizard_information',
  WIZARD_PARAMS = 'vizard_enter_params',
  WIZARD_RAPAIR_TYPE = 'vizard_choice_repair_type',
  MAP = 'map_screen',
}

export enum StatisticsActionKey {
  LINK_SEARCH = 'link_search_float_button',
  LINK = 'link_float_button',
  CLONE_CALCULATE = 'clone_calculate_button',
  DELETE_CALCULATE = 'delete_calculate_button',
  CREATE_CALCULATE = 'create_calculate_button',
  SWITCH_PROJECT = 'active_project_switch_action',
  OPTIONS_TAB = 'options_tab',
  CHANGE_OPTION = 'change_first_option_action',
  SAVE_OPTIONS_USER = 'save_options_user_button',
  SAVE_OPTIONS_SYSTEM = 'save_options_system_button',
  CANCEL_BTN = 'cancel_change_option_button',
  CANCEL_CONFIRM = 'cancel_change_option_true_button',
  CANCEL_REJECT = 'cancel_change_option_false_button',
  ESTIMATE_PHASE = 'phase_estimate',
  ESTIMATE_TAB = 'estimate_tab',
  BRIGADE_TAB = 'brigade_tab',
  CHANGE_BRIGADE = 'change_brigade_level_radion_button',
  DOWNLOAD_TAB = 'download_tab',
  DOWNLOAD_ESTIMATE = 'download_estimate_link',
  DOWNLOAD_ESTIMATE_PHASE = 'download_phase_estimate_link',
  DOWNLOAD_ORDER_DESIGN = 'order_design_project_button',
  MENU_AUTH = 'link_authorization_select',
  MENU_CLEAR_PARAMS = 'clear_order_select',
  TECH_PARAMS = 'tech_parameters_tab',
  MAIN_PARAMS = 'main_parameters_tab',
  RETURN_TO_MAIN = 'link_main_board_button',
  SAVE_PARAMS = 'save_params_button',
  STEP_WIZARD_1 = 'step1_information_vizard',
  STEP_WIZARD_2 = 'step2_information_vizard',
  STEP_WIZARD_3 = 'step3_information_vizard',
  ENTER_PARAMETERS = 'link_enter_parameters',
  ENTER_PARAMETERS_BTN = 'enter_parameters_button',
  CHOICE_REPAIR_TYPE = 'choice_repair_type_button',
  MAP_CHOICE_BUILD = 'choice_build_marker',
  MAP_CONFIRM_BUILD = 'confirm_choice_build_button',
  MAP_CONFIRM_APARTMENT = 'confirm_choice_apartment_button',
  NOT_APARTMENT = 'not_apartment_button',
}

@Injectable({ providedIn: 'root' })
export class SendStatisticsService {
  constructor(
    private http: HttpClient,
    private settings: SettingsService,
  ) {}

  /**
   * Метод только для ситуаций, где требуется один hash для отправки статистики
   * и для отправки обычного api, т.е если в таблице требование заполнен столбец endpoint
   * @param {StatisticsActionKey} actionKey - Целевое действие
   * @param {StatisticsContextEnum} ctx - Откуда делается запрос (Контекст)
   * @return {String} hash
   */
  public sendStatAndSetHeadersToEndpoint(
    actionKey: StatisticsActionKey,
    ctx: StatisticsContextEnum,
  ): string {
    const hash = this.generateHash();
    this.sendStat(actionKey, ctx, hash).subscribe();
    return hash;
  }

  public sendStat(
    actionKey: StatisticsActionKey,
    ctx: StatisticsContextEnum,
    hash?: string,
  ): Observable<{ success: true }> {
    return this.http
      .post<{ success: true }>(
        `${this.settings.v2}client/stat/send`,
        { key: actionKey },
        { headers: this.createContextHeaders(ctx, hash) },
      )
      .pipe(catchError((err) => of(null)));
  }

  public createContextHeaders(ctx: string, hash?: string): HttpHeaders {
    return new HttpHeaders()
      .set('X-Ugol-Stat-Context', ctx)
      .set('X-Ugol-Stat-Hash', hash || this.generateHash())
      .set('X-Ugol-Stat-Url', location.pathname);
  }

  private generateHash(): string {
    let hash: string = '';
    const str: string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for (let i = 1; i <= 16; i++) {
      let char = Math.floor(Math.random() * str.length + 1);

      hash += str.charAt(char);
    }

    return hash;
  }
}
