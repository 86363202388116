import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BehaviorSubject, filter, first, map, takeUntil } from 'rxjs';
import { SettingsService } from '@api/settings.service';
import { environment } from '@environments/environment';
import { AuthenticationService } from '@shared/authentication/authentication.service';
import { BaseObject } from '@shared/base/base-object';
import { ThemeState } from '@shared/states/theme.state';
import { UserState } from '@shared/states/user.state';
import { NavigationStart, Router } from '@angular/router';

@Component({
  selector: 'app-navigation-panel',
  templateUrl: './navigation-panel.component.html',
  styleUrls: ['./navigation-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class.mobile]': 'settingsService.screenBreakpoint$.value === "XXSmall"',
    '[class.hidden]': '!isAvailablePage',
  },
})
export class NavigationPanelComponent extends BaseObject {
  public get isAvailablePage(): boolean {
    return location.pathname !== '/login';
  }

  public environment = environment;
  public location = location;
  public colorState$ = new BehaviorSubject<'light' | 'dark'>('dark');

  constructor(
    public themeState: ThemeState,
    private authService: AuthenticationService,
    public settingsService: SettingsService,
    public userState: UserState,
    private router: Router,
  ) {
    super();

    router.events
      .pipe(
        filter((val) => val instanceof NavigationStart),
        map((val) => val as NavigationStart),
        takeUntil(this.destroy$),
      )
      .subscribe((val) =>
        this.colorState$.next(
          val.url === '/configurator/dashboard' || '/account/profile' || val.url === '/'
            ? 'dark'
            : 'light',
        ),
      );

    this.colorState$
      .pipe(takeUntil(this.destroy$))
      .subscribe((state) =>
        this.themeState.logo$.next(
          state === 'dark' ? '/assets/img/logo/logo-dark.svg' : '/assets/img/logo/logo-light.svg',
        ),
      );
  }

  public logout(): void {
    this.authService
      .logout()
      .pipe(first(), takeUntil(this.destroy$))
      .subscribe(() => location.replace('login'));
  }
}
